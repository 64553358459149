import React from "react";
import { Route, Routes } from "react-router-dom";
import DashBoard from "../pages/dashBoard";
import AddProduuct from "../pages/AddProduct";
import routesName from '../utils/routes.json'
function ContentComponent() {
  return (
      <Routes>
        <Route path="/" element={<DashBoard />} />
        <Route path="/Dashboard" element={<div>Home</div>} />
        <Route path={routesName["add-product"]} element={<AddProduuct />} />
        <Route path="/AddCategory" element={<div>Home</div>} />
      </Routes>
  );
}

export default ContentComponent;
