import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(
        () => JSON.parse(localStorage.getItem("isAuthenticated")) || false
      );
    
      const login = () => {
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", JSON.stringify(true));
      };
    
      const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem("isAuthenticated");
      };
  
    return (
      <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
  };
  export const useAuth = () => useContext(AuthContext);