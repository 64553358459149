import React, { useState } from "react";
import style from "./header.module.css";
import vectorImage from "../assets/Vector.png";
import { Icons } from "../utils/Icons";
function Header() {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };
  return (
    <div className={style.mainWrapper}>
      <div className={style.logo}>
        <img src={vectorImage} alt="Company Logo" />
      </div>
      <div className={style.rightSide}>
        <div className={style.leftSearch}>
          <Icons.Menu color="#202224" />
          <input
            className={style.searchInput}
            placeholder="Search for products..."
          />
        </div>
        <div className={style.icons}>
          <Icons.Noti size={29} />
          <img
            src="https://avatars.githubusercontent.com/u/90733116?v=4"
            alt="User Profile"
            className={style.profileImage}
          />
          <div className={style.userProfileIcon}>
            <div className={style.username}>
              <div className={style.name}>
                <strong>Muhammad</strong>
                <span>Admin</span>
              </div>
              <span
                className={style.dropdownIcon}
                onClick={() => toggleDropdown}
              >
                ▼
              </span>
            </div>
            {isDropdownVisible && (
              <div className={style.modalOverlay} onClick={toggleDropdown}>
                <div
                  className={style.modalContent}
                  onClick={(e) => e.stopPropagation()} // Prevents closing on content click
                >
                  <ul>
                    <li>Profile</li>
                    <li>Settings</li>
                    <li>Logout</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={style.rightIcon}>
        <Icons.Menu color="#202224" />
      </div>
    </div>
  );
}
export default Header;
