import Header from "../components/Header";
import style from "./home.module.css";
import { MdDashboard } from "react-icons/md";
import ContentComponent from "../components/contentComponent";
import SideBarComponent from "../components/sideBarComponent";


function Home() {

  return (
    <div className={style.container}>
      <div className={style.header}>
        <Header />
      </div>
      <div className={style.mainWrapper}>
        <SideBarComponent />
         <ContentComponent />
      </div>
    </div>
  );
}

export default Home;
