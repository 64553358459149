import React, { useState } from "react";
// import axios from "axios";
import "toastr/build/toastr.min.css";
// import toastr from "toastr";
import { useAuth } from "../context/AuthContext";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {login}=useAuth()
//   const loginApi=()=>{
//     axios
//      .post("https://api.khushhali.com/mobile/api/v1/auth/login", {
//        email: email,
//        password: password,
//      })
//      .then((response) => {
//        toastr.info("Login Successful!", "Welcome");
//      })
//      .catch((error) => {
//        if (error.response) {
//          toastr.error("Invalid credentials, please try again.", "Login Failed");
//        } else if (error.request) {
//          toastr.error("No response from the server. Try again later.", "Network Error");
//        } else {
//          toastr.error("An unexpected error occurred.", "Error");
//        }
//      });
// }

  const handleSubmit = (event) => {
    event.preventDefault();
    login();
  };

  return (
    <div
      style={{
        margin: "0",
        padding: "0",
        backgroundImage: "url('Shape.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "80%",
          maxWidth: "400px",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          textAlign: "center",
          fontFamily: "Arial, sans-serif",
          fontSize: "18px",
          color: "#333",
          padding: "1.5rem",
        }}
      >
        <strong>Login to Account</strong>
        <p>Please enter your email and password to continue</p>
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", flexDirection: "column", marginBottom: "15px" }}>
            <label
              style={{ fontWeight: "600", marginBottom: "5px", textAlign: "start" }}
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              style={{
                padding: "12px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontSize: "16px",
                backgroundColor: "#d8d8d8",
                color: "#202224",
              }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginBottom: "15px" }}>
            <label
              style={{ fontWeight: "600", marginBottom: "5px", textAlign: "start" }}
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
              style={{
                padding: "12px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontSize: "16px",
                backgroundColor: "#d8d8d8",
                color: "#202224",
              }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: "12px 10px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              fontSize: "16px",
              cursor: "pointer",
              width: "100%",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
