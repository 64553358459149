import React from 'react'
import style from './metricCard.module.css'
import { Icons } from '../utils/Icons'
function MetricCard({title,value,growth,growthText}) {
  return (
    <div className={style.cardContainer}>
    <div className={style.cardTitle}>{title}</div>
    <div className={style.cardContent}>
      <div className={style.cardValue}>{value}</div>
      <div className={style.cardIcon}>
        <Icons.FaUsers />
      </div>
    </div>
    <div className={style.cardGrowth}>
    
      <span className={style.tagline}>
        <div className={style.icon}>
          <Icons.FaArrowTrendUp />
        {growth}

        </div>
         {growthText}</span>
    </div>
  </div>
  )
}

export default MetricCard