import React, { useState } from "react";
import style from "./home.module.css";
import MetricCard from "../components/metricCard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ArcElement,
} from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ArcElement
);
function DashBoard() {
  const [selectedMonth, setSelectedMonth] = useState("2024-01");

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const getDaysOfMonth = (month) => {
    const [year, monthNumber] = month.split("-");
    const daysInMonth = new Date(year, monthNumber, 0).getDate();
    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(`${month}-${String(i).padStart(2, "0")}`);
    }
    return days;
  };

  const data = {
    labels: getDaysOfMonth(selectedMonth),
    datasets: [
      {
        label: "Sales",
        data: Array(getDaysOfMonth(selectedMonth).length)
          .fill()
          .map(() => Math.floor(Math.random() * 100) + 20),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgba(255, 99, 132, 1)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Profit",
        data: Array(getDaysOfMonth(selectedMonth).length)
          .fill()
          .map(() => Math.floor(Math.random() * 80) + 30),
        backgroundColor: "rgba(153, 102, 255, 0.5)",
        borderColor: "rgba(153, 102, 255, 1)",
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            day: "MMM d",
          },
        },
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(200, 200, 200, 0.2)",
        },
        title: {
          display: true,
          text: "Value",
        },
      },
    },
  };

  return (
    <div className={style.rightSide}>
      <span className={style.label}>Dashboard</span>
      <div className={style.cards}>
        <MetricCard
          title={"Total User"}
          value={"40,689"}
          growth={"8.5%"}
          growthText={"Up from yesterday"}
        />
        <MetricCard
          title={"Total Orders"}
          value={"40,689"}
          growth={"8.5%"}
          growthText={"Number of orders placed."}
        />
        <MetricCard
          title={"Total Sales"}
          value={"40,689"}
          growth={"8.5%"}
          growthText={"Up from yesterday"}
        />
        <MetricCard
          title={"Total Products"}
          value={"40,689"}
          growth={"8.5%"}
          growthText={"Up from yesterday"}
        />
      </div>

      <div className={style.viewSelect}>
        <select value={selectedMonth} onChange={handleMonthChange}>
          <option value="2024-01">January</option>
          <option value="2024-02">February</option>
          <option value="2024-03">March</option>
          <option value="2024-04">April</option>
          <option value="2024-05">May</option>
          <option value="2024-06">June</option>
          <option value="2024-07">July</option>
          <option value="2024-08">August</option>
          <option value="2024-09">September</option>
          <option value="2024-10">October</option>
          <option value="2024-11">November</option>
          <option value="2024-12">December</option>
        </select>
      </div>

      {/* Line Chart */}
      <div className={style.lineGraph}>
        <Line options={options} data={data} />
      </div>
    </div>
  );
}

export default DashBoard;
