import { TiThMenu } from "react-icons/ti";
import { IoIosNotifications } from "react-icons/io";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { MdDelete } from "react-icons/md";
import { MdAdd } from "react-icons/md";
export const Icons = {
  Menu: TiThMenu,
  Noti:IoIosNotifications,
  FaArrowTrendUp:FaArrowTrendUp,
  FaUsers:FaUsers,
  IoIosSave:IoIosSave,
  RxCross2:RxCross2,
  MdDelete:MdDelete,
  MdAdd:MdAdd
};
