import React, { useRef, useState } from "react";
import style from "./addproduct.module.css";
import { Icons } from "../utils/Icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
let shipingobject={ destinationCountryName: "", cost: "", days: "" }
function AddProduct() {
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState(["Smartwatch E2", "Smartphone X"]);
  const [images, setImages] = useState([]);
  const [newFieldName, setnewFieldName] = useState([]);
  const [variations, setVariations] = useState([
    { inventory: "", price: "", color: "" },
  ]);
  const [shippinginfo, setshippinginfo] = useState([
    shipingobject,
  ]);

  const addVariableToVariation = (index, variableName) => {
    const isValidVariableName = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(variableName);

    if (!isValidVariableName) {
      alert(
        "Invalid variable name. It must not contain spaces or special characters."
      );
      return;
    }

    setVariations((prevVariations) =>
      prevVariations.map((item, i) =>
        i === index ? { ...item, [variableName]: "" } : item
      )
    );
  };

  const fileInputRef = useRef(null);
  const addTag = () => {
    if (inputValue.trim() && !tags.includes(inputValue)) {
      setTags([...tags, inputValue.trim()]);
    }
    setInputValue("");
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };
  const handleAddImage = (event) => {
    const files = event.target.files;
    const newImages = Array.from(files).map((file) => ({
      id: URL.createObjectURL(file),
      file,
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };
  const handleRemoveImage = (id) => {
    setImages(images.filter((image) => image.id !== id));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const newImages = Array.from(files).map((file) => ({
      id: URL.createObjectURL(file),
      file,
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };
  const openFilePicker = () => {
    fileInputRef.current.click();
  };
  const handleAddVariation = () => {
    setVariations([...variations, { inventory: "", price: "", color: "" }]);
  };
  const handleAddShippingInfo = () => {
    setshippinginfo([...shippinginfo, shipingobject]);
  };

  const handleRemoveVariation = (index) => {
    setshippinginfo(shippinginfo.filter((_, i) => i !== index));
  };
  const removeVariableFromVariation = (index, variableName) => {
    setVariations((prevVariations) =>
      prevVariations
        .map((item, i) => {
          if (i === index) {
            const { [variableName]: _, ...rest } = item;
            return rest;
          }
          return item;
        })
        .filter((item) => Object.keys(item).length > 0)
    );
    
  };
  const handleVariationChange = (index, field, value) => {
    const updatedVariations = [...variations];
    updatedVariations[index][field] = value;
    setVariations(updatedVariations);
  };

  return (
    <div className={style.rightSide}>
      <span className={style.label}>Add New Products</span>
      <div className={style.card}>
        <div className={style.titleCard}>
          <span className={style.title}>Product Details</span>
          <div className={style.btns}>
            <button>
              <Icons.RxCross2 size={20} />
              Cancel
            </button>
            <button>
              <Icons.IoIosSave size={20} />
              Save Product
            </button>
          </div>
        </div>
        <div className={style.genInfoCard}>
          <div className={style.infoCard}>
            <strong>General Information</strong>
            <div className={style.titleInput}>
              <text>Product Name</text>
              <input placeholder="Smartwatch E2" />
            </div>
            <div className={style.desInput}>
              <text>Description</text>
              <ReactQuill
                theme="snow"
                value={value}
                className={style.quillEditor}
                onChange={setValue}
              />
            </div>
          </div>

          <div className={style.categoryCard}>
            <strong>Category</strong>
            <div className={style.titleInput}>
              <text>Product Name</text>
              <select className={style.selectMenu}>
                <option value="smartwatchE2">Smartwatch E2</option>
                <option value="smartphoneX">Smartphone X</option>
                <option value="laptopPro">Laptop Pro</option>
              </select>
            </div>
            <div className={style.titleInput}>
              <text>Product Name</text>
              <select className={style.selectMenu}>
                <option value="smartwatchE2">Smartwatch E2</option>
                <option value="smartphoneX">Smartphone X</option>
                <option value="laptopPro">Laptop Pro</option>
              </select>
            </div>
            <div className={style.titleInput}>
              <text>Product Tags</text>
              <div className={style.tagsContainer}>
                {tags.map((tag, index) => (
                  <div key={index} className={style.tag}>
                    <span>{tag}</span>
                    <button
                      className={style.closeButton}
                      onClick={() => removeTag(tag)}
                    >
                      ✕
                    </button>
                  </div>
                ))}
                <input
                  className={style.input}
                  type="text"
                  value={inputValue}
                  placeholder="Add a tag..."
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && addTag()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.infoCard}>
          <strong>Media</strong>

          <div className={style.container}>
            <h3>Photo</h3>
            <div
              className={style.dropZone}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {images.length === 0 ? (
                <p>Drag and drop image here, or click add image</p>
              ) : (
                <div className={style.imagePreviewContainer}>
                  {images.map((image) => (
                    <div key={image.id} className={style.imageWrapper}>
                      <img
                        src={image.id}
                        alt="Uploaded preview"
                        className={style.image}
                      />
                      <button
                        className={style.deleteButton}
                        onClick={() => handleRemoveImage(image.id)}
                      >
                        <Icons.MdDelete />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleAddImage}
              ref={fileInputRef}
              className={style.fileInput}
            />
            <button className={style.addButton} onClick={openFilePicker}>
              Add Image
            </button>
          </div>

          <div className={style.container}>
            <h3>Video</h3>
            <div
              className={style.dropZone}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {images.length === 0 ? (
                <p>Drag and drop video here, or click video</p>
              ) : (
                <div className={style.imagePreviewContainer}>
                  {images.map((image) => (
                    <div key={image.id} className={style.imageWrapper}>
                      <img
                        src={image.id}
                        alt="Uploaded preview"
                        className={style.image}
                      />
                      <button
                        className={style.deleteButton}
                        onClick={() => handleRemoveImage(image.id)}
                      >
                        <Icons.MdDelete />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleAddImage}
              ref={fileInputRef}
              className={style.fileInput}
            />
            <button className={style.addButton} onClick={openFilePicker}>
              Add Video
            </button>
          </div>
        </div>
        <div className={style.infoCard}>
          <strong>Pricing</strong>
          <div className={style.titleInput}>
            <text>Original Price</text>
            <input type="number" placeholder="Enter Price" />
          </div>
          <div className={style.titleInput}>
            <text>Sales Price(if add discount)</text>
            <input type="number" placeholder="Enter Price" />
          </div>
          <div className={style.titleInput}>
            <text>Discount Precentage (8.3%)</text>
            <input type="number" placeholder="Enter Price" />
          </div>
        </div>
        <div className={style.infoCard}>
          <strong>Pricing</strong>
          <div className={style.genInfoCard}>
            <div className={style.titleInput}>
              <text>Quantity</text>
              <input type="number" placeholder="Enter Quantity" />
            </div>
            <div className={style.titleInput}>
              <text>Processing TIme</text>
              <input type="text" placeholder="Enter Processing TIme" />
            </div>
          </div>
          <div className={style.titleInput}>
            <text>Company Name</text>
            <input type="text" placeholder="Enter Company Name" />
          </div>

          <div className={style.desInput}>
            <text>Return Policy</text>
            <ReactQuill
              theme="snow"
              value={value}
              className={style.quillEditor}
              onChange={setValue}
            />
          </div>
        </div>
        <div className={style.infoCard}>
          <div className={style.variationSection}>
            <h3>Variations</h3>
            {variations.map((variation, index) => (
              <div key={index} className={style.variationRow}>
                {Object.keys(variation).map((e) => (
                  <div className={style.addField}>
                    <text>{e}</text>
                    <div className={style.valueBox}>
                      <input
                        //  value={newFieldName}
                         onChange={(v) => handleVariationChange(index,e,v.target.value)}
                        type="text"
                        value={variation[e]}
                        placeholder="Enter Field name"
                      />

                      <button
                        onClick={() => removeVariableFromVariation(index, e)}
                      >
                        <Icons.MdDelete size={18} color={"#fff"} />
                      </button>
                    </div>
                  </div>
                ))}
                <div className={style.addField}>
                  <text>Field Name</text>
                  <div className={style.fieldBox}>
                    <input
                      value={newFieldName}
                      onChange={(e) => setnewFieldName(e.target.value)}
                      type="text"
                      placeholder="Enter Field name"
                    />
                    <button
                      onClick={() =>
                        addVariableToVariation(index, newFieldName)
                      }
                    >
                      <Icons.MdAdd size={18} color={"#fff"} />
                    </button>
                  </div>
                </div>
                <div className={style.divider} />
              </div>
            ))}
            <button className={style.addButton} onClick={handleAddVariation}>
              + Add Variant
            </button>
          </div>
        </div>
        <div className={style.infoCard}>
          <strong>Shipping</strong>
          <div className={style.variationSection}>
            {shippinginfo.map((variation, index) => (
              <div key={index} className={style.variationRow}>
                {Object.keys(variation).map((e) => (
                  <div className={style.titleInput}>
                    <text>{e.charAt(0).toUpperCase() + e.slice(1)}</text>
                    <input type="number" placeholder="Enter Quantity" />
                  </div>
                ))}
                <button
                  className={style.delect}
                  onClick={() => handleRemoveVariation(index)}
                >
                </button>
                <button class={style['delete-btn']}
                  onClick={() => handleRemoveVariation(index)}
                
                
                >Delete</button>

              </div>
            ))}
            <button className={style.addButton} onClick={handleAddShippingInfo}>
              + Add ShippingInfo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
