/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import style from "./sideleft.module.css";
import { MdDashboard } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import routeNames from '../utils/routes.json'
function SideBarComponent() {
  const [activeTab, setActiveTab] = useState("");
  const navigate=useNavigate();
  useEffect(() => {
    const pathname = window.location.pathname;
    setActiveTab(pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);
const onClick=(key)=>{
    if(key=='singOut'){

    }else{
        navigate(key);
    }
}
  return (
    <div className={style.leftSide}>
      <ul>
        <li className={activeTab == "/" ? style.active : ""}
        onClick={()=>onClick('/')}
        >
          <a className={activeTab == "/" ? style.active : ""}>
            <MdDashboard />
            Dashboard
          </a>
        </li>
        <li className={activeTab == routeNames["add-product"] ? style.active : ""}
        onClick={()=>onClick(routeNames["add-product"])}
        
        >
          <a className={activeTab == routeNames["add-product"] ? style.active : ""}>
            <MdDashboard />
            Add Product
          </a>
        </li>
       
        <li className={activeTab === "/products" ? style.active : ""}>
          <a className={activeTab === "/products" ? style.active : ""}>
            <MdDashboard />
            Add Categories
          </a>
        </li>
        <li className={activeTab === "/products" ? style.active : ""}>
          <a className={activeTab === "/products" ? style.active : ""}>
            <MdDashboard />
            Order List
          </a>
        </li>
        <li className={activeTab === "/products" ? style.active : ""}>
          <a className={activeTab === "/products" ? style.active : ""}>
            <MdDashboard />
            Invoices List
          </a>
        </li>
        <li className={activeTab === "/products" ? style.active : ""}>
          <a className={activeTab === "/products" ? style.active : ""}>
            <MdDashboard />
            Settings
          </a>
        </li>
        <li className={activeTab === "/products" ? style.active : ""}>
          <a className={activeTab === "/products" ? style.active : ""}>
            <MdDashboard />
            Log out
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SideBarComponent;
